"use strict";

// jsファイルインポート
import "./polyfill";
import * as $ from "jquery";
import Vue from "vue";

//フォーム
import "./namespace/form";

//===================================
// マーカーアニメーション
//===================================
$(window).scroll(function() {
  // マーカーの動きをつける
  $(".marker-animation").each(function() {
    const position = $(this).offset().top; //ページの一番上から要素までの距離を取得
    const scroll = $(window).scrollTop(); //スクロールの位置を取得
    const windowHeight = $(window).height(); //ウインドウの高さを取得
    if (scroll > position - windowHeight) {
      //スクロール位置が要素の位置を過ぎたとき
      $(this).addClass("marker-active"); //クラス「active」を与える
    }
  });
});

//===================================
//フェードインアニメーション
//===================================
$(function() {
  $(window).scroll(function() {
    $(".fade-in").each(function() {
      const position = $(this).offset().top;
      const scroll = $(window).scrollTop();
      const windowHeight = $(window).height();
      if (scroll > position - windowHeight + 200) {
        $(this).addClass("fade-active");
      }
    });
  });
});

//===================================
// 遅延表示アニメーション
//===================================
$(function() {
  $(".delay-show").css("opacity", "0");
  $(window).scroll(function() {
    $(".delay-show").each(function() {
      const elemPos = $(this).offset().top; /*画面上部からの距離*/
      const scroll = $(window).scrollTop(); /*スクロールした量*/
      const windowHeight = $(window).height(); /*画面の高さ*/

      /*発動条件：画面の高さが1/4までスクロールしたら順番に表示*/
      if (scroll > elemPos - windowHeight + windowHeight / 4) {
        $(function() {
          $(".delay-show").each(function(i) {
            $(this)
              .delay(500 * i)
              .animate({ opacity: 1 }, 1000);
          });
        });
      }
    });
  });
});

//===================================
// ページ内リンクのスクロールアニメーション
//===================================
$(function() {
  $('a[href^="#"]').click(function() {
    const speed = 1000;
    const href = $(this).attr("href");
    const target = $(href == "#" || href == "" ? "html" : href);
    const headerHeight = 80;
    const position = target.offset().top - headerHeight;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
});

//===================================
// ヘッダーアニメーション
//===================================
$(function() {
  $(window).on("load scroll", function() {
    const scrollPos = $(this).scrollTop();
    if (scrollPos > 0) {
      $(".js-header").addClass("header-animation");
    } else {
      $(".js-header").removeClass("header-animation");
    }
  });
});

//===================================
// タブ切り替え
//===================================
new Vue({
  el: "#js-tab",
  data: {
    isActive: "1"
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    change: function(num) {
      this.isActive = num;
    }
  }
});
