/* eslint-disable @typescript-eslint/explicit-function-return-type */
/*
 * FORM : 入力内容をAxiosでGoogle Formに送信
 */
import Vue from "vue";
import axios from "axios";

if (document.querySelector("#postForm")) {
  new Vue({
    el: "#postForm",
    data: {
      privacyChecked: false,
      errorMessageName: false,
      errorMessageCompany: false,
      errorMessageUrl: false,
      errorMessageUrlValid: false,
      errorMessageMail: false,
      errorMessageMailValid: false,
      errorMessageText: false,
      hasErrorCompany: false,
      hasErrorUrl: false,
      hasErrorName: false,
      hasErrorMail: false,
      hasErrorText: false,
      showForm: true,
      category: "お仕事のご相談やご依頼など",
      yourName: "",
      yourNumber: "",
      yourCompany: "",
      yourUrl: "",
      yourEmail: "",
      content: "",
      errors: []
    },
    methods: {
      validateForm: function() {
        // バリデーション
        if (
          this.yourCompany &&
          this.yourUrl &&
          this.yourName &&
          this.yourEmail &&
          this.content &&
          this.validUrl(this.yourUrl) &&
          this.validEmail(this.yourEmail)
        ) {
          return true;
        }
        //会社名のバリデーション
        this.errorMessage = false;
        if (!this.yourCompany) {
          this.errorMessageCompany = true;
          this.hasErrorCompany = true;
        } else {
          this.errorMessageCompany = false;
          this.hasErrorCompany = false;
        }
        //URLのバリデーション
        this.errorMessage = false;
        if (!this.yourUrl) {
          this.errorMessageUrl = true;
          this.hasErrorUrl = true;
        } else if (!this.validUrl(this.yourUrl)) {
          this.errorMessageUrlValid = true;
          this.errorMessageUrl = false;
          this.hasErrorUrl = true;
        } else {
          this.errorMessageUrlValid = false;
          this.errorMessageUrl = false;
          this.hasErrorUrl = false;
        }
        // 名前のバリデーション

        this.errorMessage = false;
        if (!this.yourName) {
          this.errorMessageName = true;
          this.hasErrorName = true;
        } else {
          this.errorMessageName = false;
          this.hasErrorName = false;
        }
        if (!this.yourEmail) {
          this.errorMessageMail = true;
          this.hasErrorMail = true;
          this.errorMessageMailValid = false;
        } else if (!this.validEmail(this.yourEmail)) {
          this.errorMessageMailValid = true;
          this.errorMessageMail = false;
          this.hasErrorMail = true;
        } else {
          this.errorMessageMailValid = false;
          this.errorMessageMail = false;
          this.hasErrorMail = false;
        }
        if (!this.content) {
          this.errorMessageText = true;
          this.hasErrorText = true;
        } else {
          this.errorMessageText = false;
          this.hasErrorText = false;
        }
      },
      // email 形式チェック
      validEmail: function(yourEmail) {
        const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regEmail.test(yourEmail);
      },
      validUrl: function(yourUrl) {
        const regUrl = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?/;
        return regUrl.test(yourUrl);
      },

      submit: function() {
        if (this.validateForm()) {
          // 入力データの送信
          const submitParams = new FormData();
          submitParams.append("entry.2021717055", this.category);
          submitParams.append("entry.305094933", this.yourCompany);
          submitParams.append("entry.760120226", this.yourUrl);
          submitParams.append("entry.579714390", this.yourName);
          submitParams.append("entry.1251003567", this.yourNumber);
          submitParams.append("entry.352326543", this.yourEmail);
          submitParams.append("entry.1370708567", this.content);
          // Cross-Origin Read Blocking対策
          const CORS_PROXY = "https://ohtam-cors-anywhere.herokuapp.com/";
          // const CORS_PROXY = "http://localhost:8080/";
          const GOOGLE_FORM_ACTION =
            "https://docs.google.com/forms/u/4/d/e/1FAIpQLSeqdKAJE8yguPmXz4nUn6THeqBgwOTiclcGZzivrYWU1HALIA/formResponse";
          // Ajax POST
          axios
            .post(CORS_PROXY + GOOGLE_FORM_ACTION, submitParams)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .then(res => {
              // フォーム非表示
              console.log("成功");
              this.showForm = false;
            });
          // フォーム非表示
          this.showForm = false;
          // }
        }
      }
    }
  });
}
